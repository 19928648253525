import actions from './actions';

const initState = {
  items: {},
  list: [],
  workspaces: {},
};

export default function reducer(state = initState, {type, payload}) {
  switch (type) {
    case actions.ADD_PRODUCTS_SUCCESS: {
      const items = {
        ...state.items,
        ...payload.items,
      };
      const workspaceItemsList = [
        ...(state[payload.workspace] || []),
        ...Object.keys(payload.items),
      ];
      const workspaces = {
        ...state.workspaces,
        [payload.workspace]: workspaceItemsList.length,
      };
      return {
        ...state,
        items,
        list: Object.keys(items),
        [payload.workspace]: workspaceItemsList,
        workspaces,
      };
    }
    default:
      return state;
  }
}
