import artImageActions from '../art-images/actions';
const DOCUMENT = 'STAGE_';

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
  RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

  TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
  FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',

  STAGE_CREATE_SUCCESS: 'STAGE_CREATE_SUCCESS',
  STAGE_ADD_ASSET: 'STAGE_ADD_ASSET',
  STAGE_REMOVE_ASSET: 'STAGE_REMOVE_ASSET',
  STAGE_UPDATE_ASSET: 'STAGE_UPDATE_ASSET',
  STAGE_CLEAR_SELECTION: 'STAGE_CLEAR_SELECTION',
  STAGE_SELECT_ASSET: 'STAGE_SELECT_ASSET',
  STAGE_SAVE_STATE: 'STAGE_SAVE_STATE',

  addAssetToStage: (assetId, itemId, generateAlpha = false) => {
    return (dispatch, getState) => {
      const product = getState().Products.items[itemId];
      const imageAsset = product && product.images.find(image => image.id === assetId);

      const asset = {};
      asset.itemId = itemId;
      asset.src = imageAsset.originalSrc;
      asset.width = 300;
      asset.height = 300;

      dispatch({
        type: actions.STAGE_ADD_ASSET, assetId, asset,
      });

      if (generateAlpha) {
        dispatch(artImageActions.generateArtImage(assetId, asset.src))
      }
    }
  },

  updateStageAsset: (assetId, updates) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.STAGE_UPDATE_ASSET, assetId, updates,
      });
      dispatch({
        type: actions.STAGE_SAVE_STATE, stage: getState().Stage,
      });
    }
  },

  removeStageAsset: (assetId) => ({
    type: actions.STAGE_REMOVE_ASSET, assetId,
  }),

  selectStageAsset: (assetId) => ({
    type: actions.STAGE_SELECT_ASSET, assetId,
  }),

  clearStageSelection: () => ({
    type: actions.STAGE_CLEAR_SELECTION,
  }),

  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE };
  },

  loadFromFireStoreSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { data },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  saveIntoFireStore: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  update: data => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  }),

  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  resetFireStoreDocuments: () => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS,
  }),

  resetFireStoreDocumentsError: error => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
    payload: { error },
  }),
};
export default actions;
