import config from '../config/site.config';
const toSimpleId = normalizedId => normalizedId.split('/').pop();
const sendResponse = async res => await res.json();

const api = {
  GET: async (path) => {
    const res = await fetch(encodeURI(`${config.apiUrl}${path}`))
    return await sendResponse(res)
  },

  POST: async (path, generateFromSrc) => {
    const res = await fetch(encodeURI(`${config.apiUrl}${path}`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({generateFromSrc}),
    });
    return await sendResponse(res)
  }
}

const media = {
  generateArtImage: async (assetId, generateFromSrc) => await api.POST(`shop/art/${toSimpleId(assetId)}`, generateFromSrc),
  getArtImage: async (assetId) => await api.GET(`shop/art/${toSimpleId(assetId)}`),
};

export {
  media
}
