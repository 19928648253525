import actions from './actions';
import uniq from 'lodash/uniq';

const initState = {
  isLoading: false,
  errorMessage: false,
  list: [],
};

export default function reducer(state = initState, { type, payload, assetId, asset, }) {
  switch (type) {
    case actions.GENERATE_ART_IMAGE_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GENERATE_ART_IMAGE_SUCCESS:
      return {
        ...state,
        [assetId]: asset,
        list: uniq([...state.list, assetId]),
        isLoading: false,
      };
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        article: payload.data == null ? initState.article : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        article: payload.data,
      };
    default:
      return state;
  }
}
