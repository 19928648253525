import actions from './actions';
import without from 'lodash/without';
import omit from 'lodash/omit';

const moveToTop = (list, assetId) => {
  const newList = [...list];
  const index = list.indexOf(assetId);
  newList.splice(index, 1);
  newList.push(assetId);
  return newList
}

const initState = {
  selectedId: '',
  list: [],
};

export default function reducer(state = initState, { type, id, assetId, asset, updates }) {
  switch (type) {
    case actions.STAGE_CREATE_SUCCESS:
      return {
        ...state, id,
      }
    case actions.STAGE_ADD_ASSET:
      return {
        ...state,
        [assetId]: asset,
        list: [...state.list, assetId],
      };
    case actions.STAGE_UPDATE_ASSET:
      // console.log('STAGE_UPDATE_ASSET reducer', updates);
      return {
        ...state,
        [assetId]: {
          ...state[assetId],
          ...updates,
        },
      };
    case actions.STAGE_REMOVE_ASSET:
      const updatedState = omit(state, assetId);
      return {
        ...updatedState,
        list: without(state.list, assetId),
      };
    case actions.STAGE_SELECT_ASSET:
      return {
        ...state,
        list: moveToTop(state.list, assetId),
        selectedId: assetId,
      };
    case actions.STAGE_CLEAR_SELECTION:
      return {
        ...state,
        selectedId: '',
      };
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        article: payload.data == null ? initState.article : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        article: payload.data,
      };
    default:
      return state;
  }
}
